import React from 'react';

import { Box } from '@core';
import { ImageCore, LOADING_OPTIONS, Loader } from '@components';

const MobileImage = ({ mapSrc }) => (
  <Box
    border="8px solid"
    borderColor={mapSrc ? 'darkBlue_20' : 'primary_20'}
    mx={24}
    mt={32}
    display="flex"
    alignItems="center"
    justifyContent="center"
    backgroundColor={mapSrc ? 'darkBlue_20' : 'primary_20'}
    width="100%"
    height="calc((100vw - 48px) * 2/3)"
  >
    {mapSrc ? (
      <ImageCore src={mapSrc} alt={'google map'} loading={LOADING_OPTIONS.eager} />
    ) : (
      <Loader isLoading={true} />
    )}
  </Box>
);

export default MobileImage;
