import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';

import { CTASection, CardsBlockSection, ContentBlockSection, LocationCta } from '@containers';
import { Card, Layout, SEO, SectionContainerSC as SectionContainer, Text } from '@components';
import { PAGE_DATA } from '@shared/constants/pages/what-is-my-ip';
import { getFetchedImage } from '@shared/image-helpers';

const WhatIsMyIp = ({ location, data }) => {
  const images = data.allFile.edges;
  const {
    metaData,
    headerCta,
    whatIsIp,
    ipAddressDetails,
    whoWantsToKnowYourIpAddress,
    howToHideIp,
    publicVsPrivate,
    protocols,
    protocolsDetails,
    steps,
    footerCta,
  } = PAGE_DATA({ images });

  const { t } = useTranslation();

  const ctaImages = {
    ctaImageProtected: getFetchedImage(images, 'what-is-ip/cta-image.png'),
    ctaImageExposed: getFetchedImage(images, 'what-is-ip/cta-image-exposed.png'),
    exposedIllustration: getFetchedImage(images, 'what-is-ip/tablet-exposed-illiustration.png'),
    exposedTablet: getFetchedImage(images, 'what-is-ip/tablet-exposed.png'),
    protectedIllustration: getFetchedImage(images, 'what-is-ip/tablet-protected-illiustration.png'),
    protectedTablet: getFetchedImage(images, 'what-is-ip/tablet-protected.png'),
  };

  return (
    <Layout
      location={location}
      wrapperProps={{ backgroundColor: 'unset' }}
      navProps={{ initialBackgroundColor: { _: '#BFE2FF', lg: 'transparent' } }}
    >
      <SEO {...metaData(t)} />

      <LocationCta images={ctaImages} data={headerCta} />

      <ContentBlockSection
        sectionProps={{ wrapperProps: { py: { _: 64, md: 88 } } }}
        {...whatIsIp(t)}
      />
      <CardsBlockSection
        {...ipAddressDetails(t)}
        itemComponent={Card.Device}
        itemsOnDesktop={4}
        sectionProps={{ wrapperProps: { pb: { _: 64, md: 88 } } }}
      />
      <CardsBlockSection
        {...whoWantsToKnowYourIpAddress(t)}
        itemComponent={Card.Feature}
        itemsOnDesktop={4}
        sectionProps={{ wrapperProps: { pb: { _: 64, md: 88 } } }}
      />
      <ContentBlockSection
        sectionProps={{ wrapperProps: { py: { _: 64, md: 88 } } }}
        {...howToHideIp(t)}
      />
      <CardsBlockSection
        {...publicVsPrivate(t)}
        itemComponent={Card.Feature}
        itemsOnDesktop={2}
        sectionProps={{ wrapperProps: { py: { md: 64 } } }}
      />
      <CardsBlockSection
        {...protocols(t)}
        itemComponent={Card.Feature}
        itemsOnDesktop={2}
        sectionProps={{ wrapperProps: { pt: { md: 64 }, pb: { md: 0 } } }}
      />
      <SectionContainer {...protocolsDetails(t)} wrapperProps={{ pt: 0, pb: { md: 80 } }} />
      <CardsBlockSection
        {...steps(t)}
        itemComponent={Card.Feature}
        itemsOnDesktop={3}
        sectionProps={{ wrapperProps: { pb: { _: 64, md: 88 } } }}
      />
      <CTASection
        data={footerCta}
        showAvailablePlatforms
        titleComponent={Text.Heading2}
        sectionProps={{
          wrapperProps: { pb: 0 },
        }}
        wrapperProps={{
          backgroundColor: '#BFE2FF',
        }}
        contentProps={{
          pt: 160,
          pb: 80,
        }}
      />
    </Layout>
  );
};

export default WhatIsMyIp;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { in: ["common", "navigation", "whatIsMyIp"] } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile(
      filter: {
        relativePath: {
          in: [
            "what-is-ip/cta-image.png"
            "what-is-ip/cta-image-exposed.png"
            "what-is-ip/tablet-exposed-illiustration.png"
            "what-is-ip/tablet-exposed.png"
            "what-is-ip/tablet-protected-illiustration.png"
            "what-is-ip/tablet-protected.png"
            "what-is-ip/how-to-hide-ip.png"
            "what-is-ip/public-ip.png"
            "what-is-ip/private-ip.png"
            "what-is-ip/ipv4.png"
            "what-is-ip/ipv6.png"
            "what-is-ip/advertisers.png"
            "what-is-ip/authorities.png"
            "what-is-ip/cybercriminals.png"
            "what-is-ip/employers.png"
            "what-is-ip/what-is-ip.png"
          ]
        }
        sourceInstanceName: { eq: "images" }
      }
    ) {
      edges {
        ...imageData
      }
    }
  }
`;
