import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid } from '@core';
import checkMark from '@images/what-is-ip/checkmark.svg';
import warningSign from '@images/what-is-ip/warningSign.svg';
import { Flag, ImageCore, Text } from '@components';
import { useI18nCountries } from '@hooks';

const IconPlaceholder = () => (
  <Box width={32} height={32} borderRadius="50%" backgroundColor="primary_40" />
);

const FlagIcon = ({ iso }) => (iso ? <Flag width={32} iso={iso} /> : <IconPlaceholder />);

const StatusIcon = ({ src }) =>
  src ? <ImageCore width={32} src={src} alt="check mark" /> : <IconPlaceholder />;

const Row = ({ backgroundColor, icon, text, isLoading }) => {
  return (
    <Box
      px={8}
      py={8}
      backgroundColor={isLoading && backgroundColor ? 'primary_20' : backgroundColor}
      borderRadius={60}
      maxWidth={430}
    >
      <Grid.Row flexWrap="no-wrap">
        <Box display="flex">{icon}</Box>
        {isLoading ? (
          <Box width="100%" ml={16} backgroundColor="primary_20" borderRadius={60} />
        ) : (
          <Text.Body2 pl={16}>{text}</Text.Body2>
        )}
      </Grid.Row>
    </Box>
  );
};

const Description = ({ userLocation }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { i18nCountries } = useI18nCountries(language);

  return (
    <Box>
      <Text.Body2>{t('whatIsMyIp:headerCta.locationTexts.yourIpAddress')}</Text.Body2>
      <Row
        isLoading={!userLocation || !i18nCountries}
        text={
          <strong>
            {userLocation?.ip_address} (
            {i18nCountries?.getName(userLocation?.country_code, language, { select: 'alias' })},{' '}
            {userLocation?.city_name})
          </strong>
        }
        icon={<FlagIcon iso={userLocation?.country_code} />}
      />
      <Box py={8} />
      <Row
        isLoading={!userLocation}
        backgroundColor={userLocation?.exit_ip ? 'success_20' : 'danger_20'}
        text={
          userLocation?.exit_ip
            ? t('whatIsMyIp:headerCta.locationTexts.protectedMessage')
            : t('whatIsMyIp:headerCta.locationTexts.exposedMessage')
        }
        icon={
          <StatusIcon src={userLocation && (userLocation?.exit_ip ? checkMark : warningSign)} />
        }
      />
      {userLocation && !userLocation.exit_ip && (
        <Text.Body2 mt={32}>{t('whatIsMyIp:headerCta.locationTexts.protectIt')}</Text.Body2>
      )}
    </Box>
  );
};

export default Description;
