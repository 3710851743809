import React from 'react';
import { Description } from '@containers/what-is-my-ip/location-cta/components';

import { META_IMAGE, PRICING_LINK } from '@shared/constants/constants';
import { GA4_EVENTS } from '@shared/constants/google';
import { getFetchedImage } from '@shared/image-helpers';
// Footer CTA images
import footerImage from '@images/product-page/common-footer-image.png';
import footerImageTablet from '@images/product-page/common-footer-image-tablet.png';
import footerImageTabletTop from '@images/product-page/common-footer-image-tablet-top.png';
import footerImageMobile from '@images/product-page/common-footer-image-mobile.png';
import footerImageMobileTop from '@images/product-page/common-footer-image-mobile-top.png';
// Steps images
import downloadInstall from '@images/what-is-ip/download-and-install.svg';
import connectEnjoy from '@images/what-is-ip/connect-enjoy.svg';
import checkIp from '@images/what-is-ip/check-ip-address.svg';
// Content block images
import internetProvider from '@images/what-is-ip/internet-provider.svg';
import zipCode from '@images/what-is-ip/zip-code.svg';
import city from '@images/what-is-ip/city.svg';
import country from '@images/what-is-ip/country.svg';

export const PAGE_DATA = ({ images }) => ({
  metaData: (t) => ({
    title: t('whatIsMyIp:metaData.title'),
    description: t('whatIsMyIp:metaData.description'),
    image: META_IMAGE,
  }),
  headerCta: ({ userLocation }) => ({
    text: ({ t }) => ({
      title: t('whatIsMyIp:headerCta.title'),
      description: <Description userLocation={userLocation} />,
      buttons:
        userLocation && !userLocation?.exit_ip
          ? [
              {
                title: t('common:actions.hideMyIp'),
                link: PRICING_LINK,
                target: '_blank',
                rel: 'noopener noreferrer',
              },
            ]
          : null,
    }),
    /*
     * images are passed through location-cta component
     * this is only an exception workaround so image objects exist and components are rendered in cta-section
     */
    image: () => ({ mobile: {}, tablet: {}, desktop: {} }),
  }),
  whatIsIp: (t) => ({
    data: [
      {
        image: getFetchedImage(images, 'what-is-ip/what-is-ip.png'),
        title: t('whatIsMyIp:contentBlocks.whatIsIp.title'),
        description: t('whatIsMyIp:contentBlocks.whatIsIp.description'),
      },
    ],
  }),
  ipAddressDetails: (t) => ({
    title: t('whatIsMyIp:ipDetailsCards.title'),
    description: t('whatIsMyIp:ipDetailsCards.description'),
    items: [
      {
        image: internetProvider,
        title: t('whatIsMyIp:ipDetailsCards.isp.title'),
      },
      {
        image: zipCode,
        title: t('whatIsMyIp:ipDetailsCards.zipCode.title'),
      },
      {
        image: city,
        title: t('whatIsMyIp:ipDetailsCards.city.title'),
      },
      {
        image: country,
        title: t('whatIsMyIp:ipDetailsCards.country.title'),
      },
    ],
  }),
  whoWantsToKnowYourIpAddress: (t) => ({
    title: t('whatIsMyIp:whoWantsToKnowIpCards.title'),
    description: t('whatIsMyIp:whoWantsToKnowIpCards.description'),
    items: [
      {
        image: getFetchedImage(images, 'what-is-ip/advertisers.png'),
        title: t('whatIsMyIp:whoWantsToKnowIpCards.advertisers.title'),
        description: t('whatIsMyIp:whoWantsToKnowIpCards.advertisers.description'),
      },
      {
        image: getFetchedImage(images, 'what-is-ip/authorities.png'),
        title: t('whatIsMyIp:whoWantsToKnowIpCards.authorities.title'),
        description: t('whatIsMyIp:whoWantsToKnowIpCards.authorities.description'),
      },
      {
        image: getFetchedImage(images, 'what-is-ip/cybercriminals.png'),
        title: t('whatIsMyIp:whoWantsToKnowIpCards.cybercriminals.title'),
        description: t('whatIsMyIp:whoWantsToKnowIpCards.cybercriminals.description'),
      },
      {
        image: getFetchedImage(images, 'what-is-ip/employers.png'),
        title: t('whatIsMyIp:whoWantsToKnowIpCards.employers.title'),
        description: t('whatIsMyIp:whoWantsToKnowIpCards.employers.description'),
      },
    ],
  }),
  howToHideIp: (t) => ({
    data: [
      {
        image: getFetchedImage(images, 'what-is-ip/how-to-hide-ip.png'),
        isReversed: true,
        title: t('whatIsMyIp:contentBlocks.howToHideIp.title'),
        description: t('whatIsMyIp:contentBlocks.howToHideIp.description'),
      },
    ],
  }),
  publicVsPrivate: (t) => ({
    title: t('whatIsMyIp:publicPrivateCards.title'),
    description: t('whatIsMyIp:publicPrivateCards.description'),
    items: [
      {
        image: getFetchedImage(images, 'what-is-ip/public-ip.png'),
        imageAlt: 'public-ip',
      },
      {
        image: getFetchedImage(images, 'what-is-ip/private-ip.png'),
        imageAlt: 'private-ip',
      },
    ],
  }),
  protocols: (t) => ({
    title: t('whatIsMyIp:protocolCards.title'),
    description: t('whatIsMyIp:protocolCards.description'),
    items: [
      {
        image: getFetchedImage(images, 'what-is-ip/ipv4.png'),
        imageAlt: 'ipv4',
        description: t('whatIsMyIp:protocolCards.ipv4.description'),
      },
      {
        image: getFetchedImage(images, 'what-is-ip/ipv6.png'),
        imageAlt: 'ipv4',
        description: t('whatIsMyIp:protocolCards.ipv6.description'),
      },
    ],
  }),
  protocolsDetails: (t) => ({
    description: t('whatIsMyIp:protocolCards.bothProtocols'),
  }),
  steps: (t) => ({
    title: t('whatIsMyIp:stepCards.title'),
    items: [
      {
        image: downloadInstall,
        title: t('whatIsMyIp:stepCards.downloadInstall.title'),
        description: t('whatIsMyIp:stepCards.downloadInstall.description'),
        button: {
          link: PRICING_LINK,
          title: t('common:actions.getAtlasVpn'),
        },
      },
      {
        image: connectEnjoy,
        title: t('whatIsMyIp:stepCards.connectToServer.title'),
        description: t('whatIsMyIp:stepCards.connectToServer.description'),
      },
      {
        image: checkIp,
        title: t('whatIsMyIp:stepCards.checkIp.title'),
        description: t('whatIsMyIp:stepCards.checkIp.description'),
      },
    ],
  }),
  footerCta: {
    text: ({ pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('whatIsMyIp:footerCta.title'),
      description: t('whatIsMyIp:footerCta.description'),
    }),
    image: () => ({
      mobile: {
        src: footerImageMobile,
        alt: 'what-is-my-ip footer',
      },
      mobileTop: {
        src: footerImageMobileTop,
        alt: 'what-is-my-ip footer',
      },
      tablet: {
        src: footerImageTablet,
        alt: 'what-is-my-ip footer',
      },
      tabletTop: {
        src: footerImageTabletTop,
        alt: 'what-is-my-ip footer',
      },
      desktop: {
        src: footerImage,
        alt: 'what-is-my-ip footer',
        position: {
          bottom: 0,
          height: 540,
          maxWidth: 3000,
        },
      },
    }),
  },
});
