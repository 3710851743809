import React from 'react';

import { Box } from '@core';
import { ImageCore, LOADING_OPTIONS, Loader } from '@components';

const TabletImage = ({ mapSrc, image1, image2 }) => (
  <Box mt={64} mx={46} width="100%" position="relative">
    <Box
      border="8px solid"
      borderColor={mapSrc ? 'darkBlue_20' : 'primary_20'}
      backgroundColor={mapSrc ? 'darkBlue_20' : 'primary_20'}
      alignItems="center"
      justifyContent="center"
      height={280}
      width={360}
      display="flex"
    >
      {mapSrc ? (
        <ImageCore src={mapSrc} alt={'google map'} loading={LOADING_OPTIONS.eager} />
      ) : (
        <Loader isLoading={true} />
      )}
    </Box>
    {mapSrc && (
      <React.Fragment>
        <Box position="absolute" top="0" left={310} zIndex="-1">
          <ImageCore
            src={image1.src}
            alt={image1.alt}
            loading={LOADING_OPTIONS.eager}
            style={{ maxHeight: 260 }}
          />
        </Box>
        <Box position="absolute" top={150} left={350}>
          <ImageCore src={image2.src} alt={image2.alt} loading={LOADING_OPTIONS.eager} />
        </Box>
      </React.Fragment>
    )}
  </Box>
);

export default TabletImage;
