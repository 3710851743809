import React from 'react';

import { Box } from '@core';
import { ImageCore, LOADING_OPTIONS, Loader } from '@components';

const DesktopImage = ({ mapSrc, image }) => (
  <Box
    border="8px solid"
    borderColor={mapSrc ? 'darkBlue_20' : 'primary_20'}
    position="absolute"
    top={200}
    minWidth={450}
    maxWidth={530}
    width={546}
    height={308}
    left="55%"
  >
    <Box
      position="relative"
      display="flex"
      backgroundColor={mapSrc ? 'darkBlue_20' : 'primary_20'}
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      {mapSrc ? (
        <React.Fragment>
          <ImageCore src={mapSrc} alt={'google map'} loading={LOADING_OPTIONS.eager} />
          <Box position="absolute" top="80%">
            <ImageCore src={image.src} alt={image.alt} loading={LOADING_OPTIONS.eager} />
          </Box>
        </React.Fragment>
      ) : (
        <Loader isLoading={true} />
      )}
    </Box>
  </Box>
);

export default DesktopImage;
